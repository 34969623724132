import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ColumnDefinition, ColumnType, TABLE_CONTENTS } from '../../../../shared/constants/table-contents';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JobStatisticsModel } from '../../../../shared/models/reporting/job-statistics.model';
import { MsgBannerService } from '../../../../shared/components/msg-banner/msg-banner.service';
import { JobStatisticsService } from '../../../../core/services/job-statistics.service';
import { GenericFilterObject, Page } from '../../../../shared/models/reporting/generic-filter-object';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPagedResponse } from '../../../../shared/models/IPagedResponse';

@Component({
    selector: 'app-job-statistics',
    templateUrl: './job-statistics.component.html',
    styleUrls: ['./job-statistics.component.scss']
})
export class JobStatisticsComponent implements OnInit, AfterViewInit {

    columns: ColumnDefinition[] = TABLE_CONTENTS.JOB_STATISTICS.DISPLAYED_COLUMNS;
    searchForm: FormGroup;
    genericFilterObj: GenericFilterObject = new GenericFilterObject();

    data: JobStatisticsModel[] = [];
    isSuccessValues: string[];
    processNameValues: string[];

    // table
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    displayedColumns: string[] = [];
    dataSource: MatTableDataSource<any> = new MatTableDataSource<JobStatisticsModel>();
    length = 0;
    reload = true;

    // error list
    messageList = [];
    showNotification = false;

    constructor(private fb: FormBuilder,
                private msgBanner: MsgBannerService,
                private jobStatisticsService: JobStatisticsService) {
        this.constructForm();
        this.loadIsSuccessValues();
        this.loadProcessNameValues('');
    }

    get getColumnType() {
        return ColumnType;
    }

    ngOnInit(): void {
        this.displayedColumns = this.columns.filter(d => d.isHeader === true).map(d => d.colDef);

        const genericFilterObj: GenericFilterObject = {
            form: null,
            page: new Page(0, 50, null)
        };
        this.getData(genericFilterObj);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    constructForm() {
        this.searchForm = this.fb.group({
            names: [],
            isSuccess: [],
            rangeStart: [],
            rangeEnd: []
        });
    }

    loadIsSuccessValues() {
        this.showNotification = false;

        this.jobStatisticsService.getIsSuccessValues().subscribe(
            (response: string[]) => {
                this.isSuccessValues = response;
                this.showNotification = false;
            }, error => {
                this.isSuccessValues = [];
                this.showNotification = true;
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
            }
        );
    }

    loadProcessNameValues(searchBy: string) {
        this.showNotification = false;

        this.jobStatisticsService.getProcessNameValues(searchBy).subscribe(
            (response: string[]) => {
                this.processNameValues = response;
                console.log(this.processNameValues);
                this.showNotification = false;
            }, error => {
                this.isSuccessValues = [];
                this.showNotification = true;
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
            }
        );
    }

    onSearch() {
        this.paginator.pageIndex = 0;
        this.constructGenericFilterObj();

        this.reload = true;
        this.getData(this.genericFilterObj);
    }

    onPageChange(event: PageEvent) {
        this.genericFilterObj.form = this.searchForm?.value;
        this.genericFilterObj.page = new Page(event.pageIndex, event.pageSize, this.getSortDetails(this.sort));
        this.reload = true;
        this.getData(this.genericFilterObj);
    }

    constructGenericFilterObj() {
        this.genericFilterObj.form = this.searchForm?.value;
        this.genericFilterObj.page = new Page(0, this.paginator.pageSize, null);
    }

    changeSort(event: Sort) {
        const sort: string[] = this.getSortDetails(event);
        this.genericFilterObj.page = new Page(0, this.paginator.pageSize, sort);
        this.getData(this.genericFilterObj);
    }

    getSortDetails(sortEvent: Sort): string[] {
        const sort = [];
        sort.push(sortEvent.direction === '' ? null : sortEvent.active + ',' + sortEvent.direction);
        return sort;
    }

    private getData(genericFilterObj: GenericFilterObject) {
        this.showNotification = false;

        this.jobStatisticsService.filterJobStatistics(genericFilterObj).subscribe(
            (response: IPagedResponse<JobStatisticsModel>) => {
                this.data = response.content;
                this.length = response.totalElements;
                this.dataSource = new MatTableDataSource<any>(this.data);
                this.reload = false;
                this.showNotification = false;
            }, error => {
                this.dataSource = new MatTableDataSource<any>([]);
                this.reload = false;
                this.showNotification = true;
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
            }
        );
    }
}
