import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PagesRouting} from './pages.routing';
import {PagesComponent} from './pages.component';
import {SharedModule} from '../../shared/shared.module';
import {MsgBannerService} from '../../shared/components/msg-banner/msg-banner.service';
import {CoreModule} from '../../core/core.module';
import {RouterModule} from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { UserAppClientComponent } from './reports/user-app-client/user-app-client.component';
import { PtmAssignedStoresComponent } from './reports/ptm-assigned-stores/ptm-assigned-stores.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ReordersComponent } from './reports/reorders/reorders.component';
import { NegativeAdjustmentsComponent } from './reports/negative-adjustments/negative-adjustments.component';
import { PositiveAdjustmentsComponent } from './reports/positive-adjustments/positive-adjustments.component';
import { EmptyPktComponent } from './reports/empty-pkt/empty-pkt.component';
import { CreditComponent } from './reports/credit/credit.component';
import { TransmissionComponent } from './reports/transmission/transmission.component';
import { ShpbkLocComponent } from './reports/shpbk-loc/shpbk-loc.component';
import { StockCountsComponent } from './reports/stock-counts/stock-counts.component';
import { CartonPutAwayComponent } from './reports/carton-put-away/carton-put-away.component';
import { SummaryComponent } from './reports/summary/summary.component';
import { ShipmentNotificationComponent } from './reports/shipment-notification/shipment-notification.component';
import { ConnectionTestComponent } from './connection-test/connection-test.component';
import { AllStoresComponent } from './reports/all-stores/all-stores.component';
import { JobStatisticsComponent } from './reports/job-statistics/job-statistics.component';

@NgModule({
  declarations: [
    PagesComponent,
    HomePageComponent,
    UserAppClientComponent,
    PtmAssignedStoresComponent,
    ReordersComponent,
    NegativeAdjustmentsComponent,
    PositiveAdjustmentsComponent,
    EmptyPktComponent,
    CreditComponent,
    TransmissionComponent,
    ShpbkLocComponent,
    StockCountsComponent,
    CartonPutAwayComponent,
    SummaryComponent,
    ShipmentNotificationComponent,
    ConnectionTestComponent,
    AllStoresComponent,
    JobStatisticsComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    PagesRouting,
    SharedModule,
    CoreModule
  ],
  exports: [],
  providers: [
    MsgBannerService,
    MatSnackBar
  ]
})
export class PagesModule { }
