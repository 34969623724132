import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GenericFilterObject, Page } from '../../shared/models/reporting/generic-filter-object';
import { IPagedResponse } from '../../shared/models/IPagedResponse';
import { JobStatisticsModel } from '../../shared/models/reporting/job-statistics.model';

@Injectable({
    providedIn: 'root'
})
export class JobStatisticsService {

    private _reqOptionsArgs = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    private rootURL = environment.apiResourceUri + 'job-statistics';

    constructor(private http: HttpClient,
                private datePipe: DatePipe) {
    }

    filterJobStatistics(genericFilterObj: GenericFilterObject): Observable<IPagedResponse<JobStatisticsModel>> {
        let httpParams: HttpParams = this.createCustomPageableParams(genericFilterObj.page);
        const body = genericFilterObj.form ? JSON.parse(JSON.stringify(genericFilterObj.form)) : {};

        return this.http.post<IPagedResponse<JobStatisticsModel>>(this.rootURL, body , { params: httpParams })
            .pipe(map((res: IPagedResponse<JobStatisticsModel>) => {
                return res;
            }));
    }

    createCustomPageableParams(page: Page): HttpParams {
        let httpParams = new HttpParams();

        if (page) {
            httpParams = httpParams.append('size', String(page.size));
            httpParams = httpParams.append('page', String(page.page));
        } else {
            httpParams = httpParams.set('size', String(50));
            httpParams = httpParams.set('page', String(0));
        }

        if (page && page.sort && page.sort.length > 0) {
            console.log(page)
            page.sort.forEach(s => {
                httpParams = httpParams.append('sort', s);
            });
        } else {
            httpParams = httpParams.append('sort', 'start,desc');
        }

        return httpParams;
    }

    getProcessNameValues(value: string): Observable<string[]> {
        const url = `${this.rootURL}/names`;

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const params = new HttpParams()
            .set('searchBy', value ?? '')
            .set('size', '100');

        return this.http.get<any>(
            url, { headers: headers, params: params }
        ).pipe(
            map(response => {
                    return response.content;
                }
            ));
    }

    getIsSuccessValues(): Observable<string[]> {
        const url = this.rootURL + '/is-success';

        return this.http.get<any[]>(
            url, this._reqOptionsArgs
        );
    }
}
