export class ColumnDefinition {
  colDef: string;
  colHeader: string;
  colType: ColumnType;
  colWidthClass: string;
  isHeader: boolean;
  isFilter: boolean;
  filterHint: string;
}

export enum ColumnType {
  STRING = 1,
  DATE = 2,
  DATE_TIME = 3,
  DATE_RANGE = 4,
  URL = 5,
  BOOLEAN = 6
}

export const TABLE_CONTENTS = {
  USER_APP_CLIENT: {
    DISPLAYED_COLUMNS: [
      { colDef: 'usrId', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'nam', colHeader: 'Full Name', colType: ColumnType.STRING, colWidthClass: 'w-300', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'usrType', colHeader: 'User Type Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'created', colHeader: 'Create Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'createdBy', colHeader: 'Created By', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'updated', colHeader: 'Update Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'updatedBy', colHeader: 'Updated By', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'terr', colHeader: 'Territory', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'cntry', colHeader: 'Country', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null }
    ],
    EXPANDABLE: false
  },
  PTM_ASSIGNED_STORES: {
    DISPLAYED_COLUMNS: [
      { colDef: 'usrId', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'nam', colHeader: 'Full Name', colType: ColumnType.STRING, colWidthClass: 'w-300', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'terr', colHeader: 'Territory', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null }
    ],
    EXPANDABLE: true,
    EXPANDABLE_VAR_NAME: 'custs',
    SUB_TABLE_DISPLAYED_COLUMNS: [
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'nam', colHeader: 'Account Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'stAdr', colHeader: 'Street Address', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'terr', colHeader: 'Account Territory Nbr', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'exp', colHeader: 'Expiration Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'type', colHeader: 'Request Type', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'posr', colHeader: 'POSR', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'sbt', colHeader: 'SBT', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'crTyp', colHeader: 'Credit Type', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'crRefReq', colHeader: 'Credit Ref Required', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'ptmSvc', colHeader: 'Carton Put Away', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'evdyPoReq', colHeader: 'Everyday PO Required', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'strOwnRef', colHeader: 'Store Own Ref', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'sta', colHeader: 'Account Status', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
    ]
  },
  REORDERS: {
    DISPLAYED_COLUMNS: [
      { colDef: 'createdBy', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'usrNam', colHeader: 'Full Name', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'nam', colHeader: 'Account Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'terr', colHeader: 'Territory', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'created', colHeader: 'Create Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'updated', colHeader: 'Update Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'sta', colHeader: 'Request Status', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'totQty', colHeader: 'Total Quantity', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'lnItms', colHeader: 'Total Line Item', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
    ],
    EXPANDABLE: false
  },
  NEGATIVE_ADJ: {
    DISPLAYED_COLUMNS: [
      { colDef: 'createdBy', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'usrNam', colHeader: 'Full Name', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'nam', colHeader: 'Account Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'terr', colHeader: 'Territory', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'reas', colHeader: 'Reason', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'created', colHeader: 'Create Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'updated', colHeader: 'Update Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'sta', colHeader: 'Request Status', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'totQty', colHeader: 'Total Quantity', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'lnItms', colHeader: 'Total Line Item', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null }
    ],
    EXPANDABLE: false
  },
  POSITIVE_ADJ: {
    DISPLAYED_COLUMNS: [
      { colDef: 'createdBy', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'usrNam', colHeader: 'Full Name', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'nam', colHeader: 'Account Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'terr', colHeader: 'Territory', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'created', colHeader: 'Create Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'updated', colHeader: 'Update Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'sta', colHeader: 'Request Status', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'totQty', colHeader: 'Total Quantity', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'lnItms', colHeader: 'Total Line Item', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null }
    ],
    EXPANDABLE: false
  },
  EMPTY_PKTS: {
    DISPLAYED_COLUMNS: [
      { colDef: 'createdBy', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'usrNam', colHeader: 'Full Name', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'nam', colHeader: 'Account Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'terr', colHeader: 'Territory', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'created', colHeader: 'Create Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'updated', colHeader: 'Update Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'sta', colHeader: 'Request Status', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'lnItms', colHeader: 'Total Line Item', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null }
    ],
    EXPANDABLE: false
  },
  CREDITS: {
    DISPLAYED_COLUMNS: [
      { colDef: 'createdBy', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'usrNam', colHeader: 'Full Name', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'nam', colHeader: 'Account Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'terr', colHeader: 'Territory', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'created', colHeader: 'Create Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'updated', colHeader: 'Update Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'sta', colHeader: 'Request Status', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'totQty', colHeader: 'Total Quantity', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'lnItms', colHeader: 'Total Line Item', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'totWhslAmt', colHeader: 'Total Wholesale Amount', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'reas', colHeader: 'Reason', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'custClaimNbr', colHeader: 'Customer Claim Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null }
    ],
    EXPANDABLE: false
  },
  TRANSMISSIONS: {
    DISPLAYED_COLUMNS: [
      { colDef: 'usrId', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'started', colHeader: 'Start Time', colType: ColumnType.DATE_TIME, colWidthClass: 'w-200', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'duration', colHeader: 'Duration(seconds)', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: false, filterHint: null },
      {colDef: 'transferSz', colHeader: 'Transfer(size)', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: false, filterHint: null},
      {colDef: 'versionCd', colHeader: 'Code Version', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: false, filterHint: null},
      { colDef: 'dvcId', colHeader: 'Device', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'success', colHeader: 'Success', colType: ColumnType.BOOLEAN, colWidthClass: 'w-200', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'errCd', colHeader: 'Error Code', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: false, filterHint: null },

    ],
    EXPANDABLE: false,
  },
  SHOP_BACK_LOCATION: {
    DISPLAYED_COLUMNS: [
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-300', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'upc', colHeader: 'UPC', colType: ColumnType.STRING, colWidthClass: 'w-300', isHeader: true, isFilter: true, filterHint: 'e.g 5029376558071' },
      { colDef: 'cabPkt', colHeader: 'Rack/Pkt', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: 'e.g OQ 00089' },
      { colDef: 'posr', colHeader: 'POSR', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'ord', colHeader: 'Orderable', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'seq', colHeader: 'Sequence', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'prevRevUpc', colHeader: 'Previous UPC', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'onHndInv', colHeader: 'On Hand Inventory', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
    ],
    EXPANDABLE: false,
  },
  STOCK_COUNTS: {
    DISPLAYED_COLUMNS: [
      { colDef: 'createdBy', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'usrNam', colHeader: 'Full Name', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'nam', colHeader: 'Account Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'terr', colHeader: 'Territory', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'created', colHeader: 'Create Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'updated', colHeader: 'Update Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'sta', colHeader: 'Request Status', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'totQty', colHeader: 'Total Quantity', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'lnItms', colHeader: 'Total Line Item', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null }
    ],
    EXPANDABLE: false
  },
  CARTON_PUT_AWAY: {
    DISPLAYED_COLUMNS: [
      { colDef: 'createdBy', colHeader: 'Username', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'usrNam', colHeader: 'Full Name', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'nam', colHeader: 'Account Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'terr', colHeader: 'Territory', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'created', colHeader: 'Create Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'updated', colHeader: 'Update Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'sta', colHeader: 'Request Status', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'lnItms', colHeader: 'Total Line Item', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null }
    ],
    EXPANDABLE: false
  },
  TASKS_SUMMARY: {
    DISPLAYED_COLUMNS: [
      { colDef: 'dateProcessed', colHeader: 'Date Processed', colType: ColumnType.DATE_RANGE, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'crCnt', colHeader: '# of Credits', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'emptyPktCnt', colHeader: '# of Empty Pockets', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'negAdjCnt', colHeader: '# of Neg Adjustments', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'posAdjCnt', colHeader: '# of Pos Adjustments', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'invCnt', colHeader: '# of Stock Counts', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'reordCnt', colHeader: '# of Reorders', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'shpCnfrmCnt', colHeader: '# of Carton Put Away', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null }
    ],
    EXPANDABLE: false
  },
  SHP_NOTIF: {
    DISPLAYED_COLUMNS: [
      { colDef: 'shpNotifId', colHeader: 'ID', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'shpNotifType', colHeader: 'Type', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'invoice', colHeader: 'Invoice Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'order', colHeader: 'Order Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'carrNam', colHeader: 'Carrier Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'cart', colHeader: 'Cart', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'sta', colHeader: 'Status', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'carrTrack', colHeader: 'URL', colType: ColumnType.URL, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null }
    ],
    EXPANDABLE: true,
    EXPANDABLE_VAR_NAME: 'shpNotifId',
    SUB_TABLE_DISPLAYED_COLUMNS: [
      { colDef: 'prodCateg', colHeader: 'Category', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'qty', colHeader: 'Quantity', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null }
    ]
  },
  ALL_STORES: {
    DISPLAYED_COLUMNS: [
      { colDef: 'chainStore', colHeader: 'Account Nbr', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: 'e.g 00190-00033' },
      { colDef: 'nam', colHeader: 'Account Name', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'stAdr', colHeader: 'Street Address', colType: ColumnType.STRING, colWidthClass: 'w-200', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'terr', colHeader: 'Account Territory Nbr', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: true, filterHint: null },
      { colDef: 'exp', colHeader: 'Expiration Date', colType: ColumnType.DATE, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'posr', colHeader: 'POSR', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'sbt', colHeader: 'SBT', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'crTyp', colHeader: 'Credit Type', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'crRefReq', colHeader: 'Credit Ref Required', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'ptmSvc', colHeader: 'Carton Put Away', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'evdyPoReq', colHeader: 'Everyday PO Required', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
    ],
    EXPANDABLE: false,
  },
  JOB_STATISTICS: {
    DISPLAYED_COLUMNS: [
      { colDef: 'name', colHeader: 'Process Name', colType: ColumnType.STRING, colWidthClass: 'w-300', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'start', colHeader: 'Start Date', colType: ColumnType.DATE_TIME, colWidthClass: 'w-175', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'end', colHeader: 'End Date', colType: ColumnType.DATE_TIME, colWidthClass: 'w-175', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'formattedDuration', colHeader: 'Duration', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'inserted', colHeader: 'Records Inserted', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'updated', colHeader: 'Records Updated', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'deleted', colHeader: 'Records Deleted', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'isSuccess', colHeader: 'Success', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'status', colHeader: 'Status', colType: ColumnType.STRING, colWidthClass: 'w-150', isHeader: true, isFilter: false, filterHint: null },
      { colDef: 'err', colHeader: 'Error', colType: ColumnType.STRING, colWidthClass: 'w-75', isHeader: true, isFilter: false, filterHint: null },
    ],
    EXPANDABLE: false,
  }
};
