import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../modules/material.module';
import {MsgBannerComponent} from './components/msg-banner/msg-banner.component';
import {NotAuthorizedComponent} from './components/not-authorized/not-authorized.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import { GenericTableComponent } from './components/generic-table/generic-table.component';
import {DateFormatPipe} from '../core/pipes/date-formater-pipe';
import { GhostTableComponent } from './components/ghost-table/ghost-table.component';
import { GenericTableDialogComponent } from './components/generic-table-dialog/generic-table-dialog.component';
import {DateTimeFormatterPipe} from '../core/pipes/date-time-formatter.pipe';

@NgModule({
  declarations: [
    SidebarComponent,
    ConfirmationDialogComponent,
    MsgBannerComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    GenericTableComponent,
    DateFormatPipe,
    DateTimeFormatterPipe,
    GhostTableComponent,
    GenericTableDialogComponent
  ],
  imports: [
    MaterialModule,
    RouterModule,
    CommonModule,
    MatProgressBarModule
  ],
  exports: [
    ConfirmationDialogComponent,
    SidebarComponent,
    MsgBannerComponent,
    MaterialModule,
    GenericTableComponent,
    GhostTableComponent,
    DateFormatPipe,
    DateTimeFormatterPipe
  ],
  providers: [],
})
export class SharedModule { }
