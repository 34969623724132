import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GraphService} from '../../../core/graph/graph.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {

  message = 'Opps, it seems that you are not authorized to see this page.';

  constructor(private router: Router,
              private graphService: GraphService) { }

  ngOnInit(): void {
    if (history.state.data) {
      this.message = history.state.data;
    }
  }

  public goHome(): void {
    this.router.navigate(['/']);
  }
}
